import classes from "./App.module.css";
import { Login } from "./pages/Login";
import splogo from "./assets/images/sp_logo.png";

function App() {
  return (
    <>
      <div className={classes.wrapper}>
        <Login />
        <Footer />
      </div>
    </>
  );
}

function Footer() {
  return (
    <div className={classes.footer}>
      <div className={classes.copyrightLinks}>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://www.spglobal.com/en/privacy/privacy-policy-english"
              rel="noreferrer"
            >
              {" "}
              Privacy and Cookie Policy
            </a>
          </li>
          <li> | </li>
          <li>
            <a
              target="_blank"
              href="https://www.spglobal.com/en/terms-of-use"
              rel="noreferrer"
            >
              {" "}
              Terms Of Use
            </a>
          </li>
          <li> | </li>
          <li>
            <a
              target="_blank"
              href="https://www.spglobal.com/en/terms-of-use"
              rel="noreferrer"
            >
              {" "}
              Disclaimer
            </a>
          </li>
          <li> | </li>
          <li>
            <a
              target="_blank"
              href="https://www.spglobal.com/en/contact-us"
              rel="noreferrer"
            >
              {" "}
              Contact Us
            </a>
          </li>
        </ul>
        <small className={classes.copyrightText}>
          &copy;{new Date().getFullYear()} S&P Global All rights reserved.
          Reproduction in whole or part without permission is prohibited.
        </small>
      </div>
      <div className={classes.spLogo}>
        <img src={splogo} alt="logo"></img>
      </div>
    </div>
  );
}

export default App;
