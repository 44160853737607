import classes from "./Login.module.css";
import { useState } from "react";
import { useInput } from "../hooks/useInput";
import axios from "axios";
import { loginConstants } from "./LoginConstants";
import logo from "../assets/images/SPGMI-logo.svg";

export function Login() {
  const [username] = useInput("");
  const [password, resetPassword] = useInput("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const submit = (e) => {
    e.preventDefault();
    const data = new URLSearchParams();
    data.append("userName", username.value);
    data.append("password", password.value);
    var authOptions = {
      method: "post",
      // url: "http://localhost:8080/auth/token",
      url: "/auth/token",
      data: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      json: true,
    };
    setErrorMessage("");
    setIsLoading(true);
    axios(authOptions)
      .then((response) => {
        if (response.data.access_token != null) {
          window.open(
            `${response.data.url}/Authentication?Report=605&IB_TICKET=${response.data.access_token}`,
            "_self"
          );
        } else if (response.data.error === "invalid_grant") {
          setErrorMessage(response.data.error_description);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.error_description != null) {
          setErrorMessage(err.error_description);
        } else {
          setErrorMessage("Unknown Error Occured");
        }
        setIsLoading(false);
      });
    resetPassword();
  };

  return (
    <>
      <div className={classes.login}>
        <img className={classes.logo} src={logo} alt="logo"></img>
        <div className={classes.loginForm}>
          {errorMessage && <div className={classes.alert}>{errorMessage}</div>}
          {isLoading && <div>Loading...</div>}
          <div class={classes.auth}>
            <span class={classes.authHeader}>Trading Analytics</span>
          </div>
          <form onSubmit={submit}>
            <div className={classes.formGgroup}>
              <input
                required
                className={classes.formControl}
                {...username}
                type="text"
                placeholder={loginConstants.USER_NAME_PLACEHOLDER}
              />
            </div>
            <div className={classes.formGgroup}>
              <input
                required
                className={classes.formControl}
                {...password}
                type="password"
                placeholder={loginConstants.PASSWORD_PLACEHOLDER}
              />
            </div>
            <div>
              <button
                disabled={!username.value || !password.value}
                className={classes.btnSubmit}
              >
                Submit
              </button>
            </div>
          </form>
          <div className={classes.loginFooter}>
            <a
              href="https://products.markit.com/home/resetPasswordRequest.jsp"
              target="_blank"
              rel="noreferrer"
            >
              Forgot your password?
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
